import { useContext } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { AuthContext } from "../../../contexts/auth";
import { QuestionContext, TQuestionContext } from "../../../contexts/question";
import { ERoutesPath } from "../../../routes";

import { Button } from "../../atoms/Button";
import { TEditQuestionProps } from "./types";
import * as S from "./styles";

export const EditQuestion = ({ question }: TEditQuestionProps) => {
  const { typeUser } = useContext(AuthContext);
  const { setSelectedQuestion } = useContext<TQuestionContext>(QuestionContext);

  const navigate = useNavigate();
  const handleRedirect = () => {
    setSelectedQuestion(question);
    navigate(ERoutesPath.ADMINISTRAR_QUESTOES);
  };

  return (
    <>
      {typeUser === "10" && (
        <S.Container>
          <Button size="small" onClick={handleRedirect} isSecondary>
            Editar questão
          </Button>
        </S.Container>
      )}
    </>
  );
};

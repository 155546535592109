import { useContext, useEffect, useRef, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { differenceInDays, format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

import api from "../../services/api";
import { AuthContext } from "../../contexts/auth";
import { ERoutesPath } from "../../routes";
import { useNavigate } from "react-router-dom-v5-compat";
import { Button, PageTemplate, Typography } from "../../components";
import { capitalizeWords, scrollToTop } from "../../helpers";

import { TQuestionRevision } from "./types";
import * as S from "./styles";

export const Revisions = () => {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [dataRevisions, setDataRevision] = useState<Array<TQuestionRevision>>(
    []
  );

  const idAccount = user.id_user;
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
    handleGetQuestions();
  }, []);

  const handleGetQuestions = async () => {
    try {
      setIsLoading(true);
      await api.get(`api/student/generalRevision/${idAccount}`);
      const revisionData = await api.get(
        `api/student/validate/review/${idAccount}`
      );
      setDataRevision(revisionData.data);
    } catch (error) {}
    setIsLoading(false);
  };

  const handleNavigation = (question: TQuestionRevision) => {
    if (question.difficulty === "Diversificado") {
      navigate(ERoutesPath.REVISAO_GERAL, { state: question });
    } else {
      navigate(ERoutesPath.REVISAO, { state: question });
    }
  };

  const handleRedirectStudies = () => {
    navigate(ERoutesPath.ASSUNTOS_ESTUDADOS);
  };

  const renderButton = (qtdDays: number, question: TQuestionRevision) => {
    const { is_review } = question;

    if (qtdDays === 0 && !is_review) {
      return (
        <Button
          size="small"
          bgColor="#029198"
          onClick={() => handleNavigation(question)}
        >
          Realizar revisão
        </Button>
      );
    }

    if (qtdDays > 0 && !is_review) {
      return (
        <S.QuestionsStatus>
          <Typography variant="small" color="#9C9B9B" weight={700}>
            Disponível em {qtdDays} dia(s)
          </Typography>
        </S.QuestionsStatus>
      );
    }

    if (qtdDays < 0 && !is_review) {
      return (
        <>
          <S.QuestionsStatus color="#f18a8a">
            <Typography variant="small" color="#f18a8a" weight={700}>
              Atrasado
            </Typography>
          </S.QuestionsStatus>
          <Button size="small" onClick={() => handleNavigation(question)}>
            Revise agora
          </Button>
        </>
      );
    }

    if (!is_review) {
      return (
        <S.QuestionsStatus color="#1B3C68">
          <Typography variant="small" color="#1B3C68" weight={700}>
            Revisão finalizada
          </Typography>
        </S.QuestionsStatus>
      );
    }

    return null;
  };

  const revisionsBreadcrumb = [
    { label: "Página inicial", route: ERoutesPath.ROOT },
    {
      label: "Cronograma de revisões",
    },
  ];

  return (
    <PageTemplate
      title="Cronograma de revisões"
      breadcrumbsItems={revisionsBreadcrumb}
    >
      {isLoading ? (
        <S.Center>
          <CircularProgress />
        </S.Center>
      ) : (
        <S.QuestionContainer>
          <Button isSecondary onClick={handleRedirectStudies}>
            Marcar assuntos estudados
          </Button>

          {dataRevisions.length === 0 ? (
            <Typography variant="small">
              Não existem revisões pendentes
            </Typography>
          ) : (
            <>
              {dataRevisions.map((question: TQuestionRevision, i) => {
                const revisionDay = new Date(question.review_day);
                const currentDate = new Date();

                const days = differenceInDays(revisionDay, currentDate) + 1;
                return (
                  <S.Question key={question.id}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        Revisão - {capitalizeWords(question.area)} | Nível -{" "}
                        {question.difficulty}
                      </Typography>
                      <Typography variant="small">
                        Data da revisão -{" "}
                        {format(parseISO(question.review_day), "PPP", {
                          locale: ptBR,
                        })}
                      </Typography>
                    </div>

                    <S.Actions>{renderButton(days, question)}</S.Actions>
                  </S.Question>
                );
              })}
            </>
          )}
        </S.QuestionContainer>
      )}
    </PageTemplate>
  );
};
